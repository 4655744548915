.c-view-song-detail {
  .field-image {
    display: flex;
    align-items: center;

    img {
      border-radius: 6px;
      width: 112px;
    }

    .ant-image-mask {
      border-radius: 6px;
    }
  }

  .field-artists {
    .artist {
      display: flex;
      align-items: center;
      gap: 4px;
      height: 24px;

      img {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        object-fit: cover;
        border: var(--border-shape-1);
      }

      .stage-name {
        font-size: 12px;
      }
    }
  }

  .field-group {
    display: flex;
    align-items: center;
    gap: 8px;

    .thumbnail {
      .ant-image-mask {
        border-radius: 6px;
        padding: 4px;
      }

      img {
        border-radius: 6px;
        width: 64px;
        height: 64px;
        object-fit: cover;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .day-release,
      .type {
        display: flex;
        align-items: center;
        gap: 2px;
      }
    }
  }

  .field-file {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .field-slider-type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 112px;
    border-radius: 8px;
    height: 48px;

    .name {
      font-weight: 600;
      color: white;
    }
  }
}
