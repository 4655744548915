.c-confirm-content {
  .change-condition {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }

  .column-name {
    display: flex;
    align-items: center;
    gap: 12px;

    .avatar {
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      overflow: hidden;
      border-radius: 50%;
      border: var(--border-2);
      color: var(--color-neutral-1);
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .right {
      display: flex;
      flex-direction: column;

      .name {
        font-weight: 600;
      }

      .username {
      }
    }
  }

  .column-condition {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}
