.p-user {
  .column-name {
    display: flex;
    align-items: center;
    gap: 12px;

    .avatar {
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      overflow: hidden;
      border-radius: 50%;
      border: var(--border-2);
      color: var(--color-neutral-1);
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .right {
      display: flex;
      flex-direction: column;

      .name {
        font-weight: 600;
      }

      .username {
      }
    }
  }

  .column-roles {
    .ant-tag {
      margin-bottom: 8px;
    }
  }

  div.ant-typography,
  .ant-typography p {
    margin-bottom: 0;
  }
}
