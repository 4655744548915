@import "@/styles/base/responsive";

.c-column-action-wrap {
  display: flex;
  justify-content: center;

  &__small-device {
    display: block;

    @include Responsive(LG) {
      display: none;
    }

    .ant-drawer-body {
      display: flex;
      flex-direction: column;
      padding: 10px !important;
    }

    .group {
      display: flex;
      flex-direction: column;

      &__label {
        font-size: 12px;
        padding: 5px 12px;
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .menu {
      display: flex;
      flex-direction: column;
      padding: 0 8px;

      .label {
        display: flex;
        align-items: center;
        padding: 5px 12px;
        gap: 4px;
        cursor: pointer;

        &:hover {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }

  &__big-device {
    display: none;

    @include Responsive(LG) {
      display: block;
    }

    &__overlay {
      .label {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
}
