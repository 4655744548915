.p-song-group {
  .column-thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 6px;
      width: 112px;
    }

    .ant-image-mask {
      border-radius: 6px;
    }
  }
}
