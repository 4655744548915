.label-selector {
  display: flex;
  align-items: center;
  gap: 6px;
}

.s-slider-type {
  .bg-color {
    width: 96px;
    height: 16px;
    border-radius: 4px;
  }
}

.s-song-group {
  display: flex;
  align-items: center;
  gap: 4px;

  img {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    object-fit: cover;
  }
}

.s-artist {
  display: flex;
  align-items: center;
  gap: 4px;

  img {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    border: var(--border-shape-1);
  }

  .s-from-email {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
