.c-view-slider-detail {
  .field-image {
    display: flex;
    align-items: center;

    img {
      border-radius: 6px;
      width: 112px;
    }

    .ant-image-mask {
      border-radius: 6px;
    }
  }

  .field-slider-type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 112px;
    border-radius: 8px;
    height: 48px;

    .name {
      font-weight: 600;
      color: white;
    }
  }
}
