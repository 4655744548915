.p-event {
  .column-name {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .thumbnail {
      .ant-image-mask {
        border-radius: 6px;
        padding: 4px;
      }

      img {
        border-radius: 6px;
        width: 90px;
        height: 90px;
        object-fit: cover;
      }
    }

    .right {
      .name {
        font-weight: 500;
      }

      .access-btn {
        &:hover {
          background-color: var(--button-danger-hover-bg-color);
        }

        background-color: var(--button-danger-bg-color);
        color: white;
        width: max-content;
        padding: 0 6px;
        border-radius: 4px;
        display: flex;
      }
    }
  }

  .column-positions {
    .ant-tag {
      cursor: pointer;
      margin: 2px;
    }
  }

  .column-group {
    display: flex;
    align-items: center;
    gap: 8px;

    .thumbnail {
      .ant-image-mask {
        border-radius: 6px;
        padding: 4px;
      }

      img {
        border-radius: 6px;
        width: 64px;
        height: 64px;
        object-fit: cover;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .day-release,
      .type {
        display: flex;
        align-items: center;
        gap: 2px;
      }
    }
  }

  .column-status {
    display: flex;
    justify-content: center;

    .ant-tag {
      margin-inline-end: 0;
    }
  }

  .column-file {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
