.p-slider {
  .column-image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 6px;
      width: 112px;
    }

    .ant-image-mask {
      border-radius: 6px;
    }
  }

  .column-slider-type {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 48px;

    .name {
      font-weight: 600;
      color: white;
    }
  }
}
