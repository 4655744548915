.c-send-log {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;

  .ant-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    margin-inline-end: 0px;
    font-size: 14px;
    width: 100%;
  }

  .more-btn {
    background-color: var(--color-neutral-6);
    color: var(--color-neutral-1);
    border-radius: 4px;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: var(--color-neutral-7);
    }
  }
}
