.p-ticket-fan {
  .column-name {
    display: flex;
    align-items: center;
    gap: 6px;

    .name {
      font-weight: 600;
    }

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 2px solid white;
    }
  }

  .column-image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 6px;
      width: 112px;
    }

    .ant-image-mask {
      border-radius: 6px;
    }
  }

  .column-note {
    white-space: pre-line;
  }

  .column-ticket-payment {
    .ant-tag {
      width: 167px;
    }
  }

  .position-tab {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .ticket-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 400px;
  }
}
