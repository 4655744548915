@import "@/styles/base/responsive";

.c-update-user {
  .field-name {
    .ant-input-affix-wrapper {
      margin-bottom: 4px;

      @include Responsive(MD) {
        margin-bottom: 0;
      }
    }
  }
}
