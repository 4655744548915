@import "@/styles/base/responsive";

.p-config {
  margin-bottom: 100px;

  .body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .ant-spin-nested-loading {
      width: 100%;

      @include Responsive(MD) {
        width: 494px;
      }
    }
  }

  .action-btns {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}
