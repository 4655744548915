.copy-wrap {
  .ant-typography {
    justify-content: flex-start !important;

    .ant-typography-copy {
      margin-bottom: 4px;

      span {
        font-size: 18px;
      }
    }
  }
}
