.p-song {
  .column-name {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .thumbnail {
      .ant-image-mask {
        border-radius: 6px;
        padding: 4px;
      }

      img {
        border-radius: 6px;
        width: 80px;
        height: 80px;
        object-fit: cover;
      }

      .tag {
        cursor: pointer;
        border-radius: 12px;
      }
    }

    .right {
      .listener-counter {
        display: flex;
        align-items: center;
        gap: 2px;
      }
    }

    .artist {
      display: flex;
      margin-left: 12px;

      &-avatar {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 2px solid white;
      }
    }
  }

  .column-group {
    display: flex;
    align-items: center;
    gap: 8px;

    .thumbnail {
      .ant-image-mask {
        border-radius: 6px;
        padding: 4px;
      }

      img {
        border-radius: 6px;
        width: 64px;
        height: 64px;
        object-fit: cover;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .day-release,
      .type {
        display: flex;
        align-items: center;
        gap: 2px;
      }
    }
  }

  .column-status {
    display: flex;
    justify-content: center;

    .ant-tag {
      margin-inline-end: 0;
    }
  }

  .column-file {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
