.p-slider-type {
  .column-bg-color {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 48px;

    .name {
      font-weight: 600;
      color: white;
    }
  }
}
