.c-create-permission {
  .field-bg-color {
    display: flex;
    gap: 4px;

    .preview-bg-color {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 112px;
      border-radius: 8px;
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .color-style {
        display: flex;
      }

      .color-pickers {
        display: flex;
        gap: 4px;
      }
    }
  }
}
