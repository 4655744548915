@import "@/styles/base/responsive";

.c-config-header {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .title {
    font-size: 30px;
    font-weight: 700;
  }

  .action {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 12px;
    gap: 12px;

    @include Responsive(MD) {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      gap: 0;
    }
  }

  .right {
  }
}
