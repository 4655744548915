@import "@/styles/base/responsive";

.c-song-header {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .title {
    font-size: 30px;
    font-weight: 700;
  }

  .action {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 12px;
    gap: 12px;

    @include Responsive(MD) {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      gap: 0;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;

    @include Responsive(MD) {
      gap: 6px;
      align-items: center;
      flex-direction: row;
    }

    .selected-action-btns {
      display: flex;
      flex-direction: column;
      gap: 12px;

      @include Responsive(MD) {
        flex-direction: row;
        gap: 6px;
      }
    }
  }
}
