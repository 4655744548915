.c-view-song-group-detail {
  .field-thumbnail {
    display: flex;
    align-items: center;

    img {
      border-radius: 6px;
      width: 112px;
    }

    .ant-image-mask {
      border-radius: 6px;
    }
  }
}
