.c-view-all-log {
  display: flex;
  flex-direction: column;
  gap: 2px;

  .ant-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    cursor: pointer;
    margin-inline-end: 0px;
    font-size: 14px;
  }
}
