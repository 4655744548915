.c-send-email-all-modal {
  .c-confirm-content {
    .ant-card {
      border-radius: 6px;
    }
  }

  .attachment {
    display: flex;
    position: relative;
    flex-direction: column;
    width: max-content;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    padding: 10px;
    margin-top: 14px;

    &-name {
      position: absolute;
      top: 66px;
      left: 40px;
      font-size: 16px;
    }
  }
}
