.c-confirm-content {
  .change-condition {
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }

  .column-name {
    display: flex;
    align-items: center;
    gap: 6px;

    .name {
      font-weight: 600;
    }

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 2px solid white;
    }
  }

  .column-condition {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}
