.p-filter-boxchat {
  .field-name {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 6px;

    .avatar {
      border-radius: 50%;
      width: 36px;
      height: 36px;
      object-fit: cover;
      overflow: hidden;
      border: var(--border-shape-2);
      background-color: var(--color-neutral-1);

      img {
        width: 100%;
      }
    }
  }

  .field-action {
    display: flex;
    justify-content: center;
  }

  .generate-data {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 20px;
  }
}
