.p-email-log {
  .column-sender,
  .column-receiver {
    .name,
    .username {
      display: flex;
      gap: 2px;
      align-items: center;
    }
  }
}
